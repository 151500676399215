<section
  *ngIf="data?.message"
  [class]="
    data?.error
      ? 'bg-error/10 border-error rounded border-l-4 text-error mb-4 p-2'
      : 'bg-success/10 border-success rounded border-l-4 text-success mb-4 p-2'
  "
  role="alert"
>
  <p class="text-sm mb-0">{{ data?.message }}</p>
</section>