import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData, saveAuth } from "src/app/util/auth";

@Component({
  selector: "app-course-registration-form",
  templateUrl: "./course-registration-form.component.html",
})
export class CourseRegistrationFormComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  courses: any = [];
  filter: string = "";
  payload: any = [];

  _auth = authData();

  loading = false;
  skeletonLoader = false;

  show = false;
  notification: any = { message: "notification?.message", error: true };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {}

  performChecks(): void {
    const _check = authData("_checks");

    const {
      LateRegAppLine,
      PassportUploaded,
      AllowRegistration,
      RegistrationClosed,
      RegistrationLocked,
      OutstandingFeesName,
      StudentHasFilledBioData,
      StudentHasFilledMedicalForm,
      OutstandingFeesForRegistration,
      PaidLateRegistrationCollection,
      PassportPhotoRequiredForRegistration,
      RequireStudentToFillMedicalForm,
      // EvaluationQuestionnaireFilled,
    } = _check;

    let message = "";
    if (!AllowRegistration)
      message = "Sorry, registration is not allowed at the moment";
    else if (PassportPhotoRequiredForRegistration && !PassportUploaded)
      message = "Please upload your passport photograph";
    else if (RegistrationClosed) message = "Registration locked";
    else if (RegistrationLocked) message = "Registration is closed";
    else if (LateRegAppLine && !PaidLateRegistrationCollection)
      message = "You need to pay the late registration fee";
    else if (OutstandingFeesForRegistration)
      message = "Please pay your: " + OutstandingFeesName;
    else if (!StudentHasFilledBioData)
      message = "You haven't filled your bio-data form";
    else if (RequireStudentToFillMedicalForm && !StudentHasFilledMedicalForm)
      message = "You haven't filled your medical form";
    // if (EvaluationQuestionnaireFilled)
    //   message = "You haven't filled the evaluation questionnaire form";

    // Network/alt error handler
    if (!Object.keys(_check).length) message = "An Unexpected Error Occurred";

    this.notification = {
      message,
      error: !!message,
    };

    if (!message) this.fetchCourse();
  }

  fetchCourse() {
    this.skeletonLoader = true;

    const baseUrl = `${environment.BASE_URL}/courses/allCourses`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        if (res.Success) {
          this.skeletonLoader = false;
          this.courses = res.Data;

          this.courses.sort((a: any, b: any) =>
            a.Compulsory > b.Compulsory ? -1 : 1
          );

          this.payload = authData("_courses").Courses.map((course: any) => {
            return course.CourseCode;
          });
        } else {
          this.skeletonLoader = false;
          this.notification = {
            error: true,
            message: res.Message || "An error occurred.",
          };
        }
      },
      (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      }
    );
  }

  toggleForm() {
    if (this.loading) return;

    this.filter = "";
    this.show = !this.show;
    this.notification = {};
    if (this.show) this.performChecks();
  }

  clickCourse(courseCode: string) {
    if (this.payload.includes(courseCode))
      this.payload = this.payload.filter((item: any) => item !== courseCode);
    else this.payload.push(courseCode);
  }

  filterCourses(): any {
    return this.courses.filter(
      (course: any) =>
        course.CourseCode.toLowerCase().includes(this.filter.toLowerCase()) ||
        course.CourseTitle.toLowerCase().includes(this.filter.toLowerCase())
    );
  }

  saveCourses() {
    if (!this.payload.length) return;

    this.loading = true;
    this.notification = {};

    const baseUrl = `${environment.BASE_URL}/courses/saveCourses`;
    this.httpClient.post(baseUrl, this.payload, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.loading = false;
          const { NewMatricNo, NewToken } = res.Data;

          if (NewMatricNo) {
            saveAuth(
              {
                ...this._auth,
                NewMatricNumber: NewMatricNo,
                Token: NewToken,
              },
              "_auth"
            );
          }

          location.href = "/courses?#Courses+registered+successfully";
        } else {
          this.loading = false;
          this.notification = {
            error: true,
            message: res.Message || "An error occurred.",
          };
        }
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}
