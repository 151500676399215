export const environment = {
  production: true,
  FRONTEND_BASE_URL: "https://studentportal.mtu.edu.ng",
  BASE_URL: "//studentportal.mtu.edu.ng:7000",

  logo: "/assets/logos/mtu.png",
  short_name: "MTU",
  long_name: "Mountain Top University",

  /**
   * Navigation names includes:
   * ["home", "bio-data", "payment", "course", "result", "appointment", "election", "accommodation", "application"]
   */
   
  nav_links: {
    home: true,
    biodata: true,
    payment: true,
    course: true,
    result: true,
    appointment: false,
    election: false,
    accommodation: true,
    application: true,
  },

  remita: false,
};
