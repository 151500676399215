import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-accommodation-application-rooms",
  templateUrl: "./accommodation-application-rooms.component.html",
})
export class AccommodationApplicationRoomsComponent implements OnInit {
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}

  hallName = "";
  hallId = "";
  floors: any = [];
  reservation: any;
  response: any;
  accommodation: any;

  loading = false;
  refreshing = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const decodedHallId = decodeURIComponent(params.id).replace(/%28/g, '(').replace(/%29/g, ')');
      this.refreshing = true;

      this.accommodation = authData("_accommodation");

      if (!this.accommodation.AllowStudentsToPickAccommodationRooms) {
        this.reserveNextAvailableSpace(decodedHallId);
      } else {
        const baseUrl = `${environment.BASE_URL}/accomodation/accomodationRooms?hallId=${decodedHallId}`;
        this.httpClient.get(baseUrl, this.httpOptions).subscribe({
          next: (res: any) => {
            if (res.Success) {
              this.hallName = res.Data.HallName;
              this.hallId = res.Data.HallOfResidenceId;
              const { FloorDTO } = res.Data;
              const floors: any = [...new Array(FloorDTO.length)];

              FloorDTO.forEach((floor: any, i = 0) => {
                floors[i] = {
                  ordinal: floor.Ordinal,
                  rooms: {},
                };

                floor.Rooms.forEach((space: any) => {
                  const { X, Y } = space.Location;
                  if (!floors[i].rooms[`${X}`])
                    floors[i].rooms[`${X}`] = { 0: {}, 1: {} };
                  floors[i].rooms[`${X}`][Y > 1 ? 1 : 0] = space;
                });
              });
              this.floors = floors;
              this.refreshing = false;
            } else {
              this.notification = errorHandler(res);
            }
          },
          error: (err: any) => {
            this.notification = errorHandler(err);
          },
        });
      }
    });
  }

  getRooms(floor: any, i: any): any {
    // console.log(floor[0]);
    // return Object.keys(floor[0]);
  }

  getSpace(spaces: any): any {
    return Object.values(spaces);
  }

  getArray(values: any): any {
    return Object.values(values);
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }

  reserveNextAvailableSpace(hallId: string) {
    this.loading = true;
    this.refreshing = true;
    this.response = {
      HallId: hallId,
    };
    const baseUrl = `${environment.BASE_URL}/accomodation/saveAccommodationReservation`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          if (this.accommodation.QueueAccommodation) {
            this.router.navigateByUrl("/accommodation#submittedtoqueue=1");
          } else {
            this.router.navigateByUrl("/accommodation#submittedtoqueue=0");
          }

          this.loading = false;
          this.refreshing = false;
        } else {
          this.showUp();
          this.notification = errorHandler(res);
        }
      },
      error: (err) => {
        this.showUp();
        this.notification = errorHandler(err);
      },
    });
  }
}
