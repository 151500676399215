import { authData, saveAuth } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "src/app/http/data.service";

@Component({
  selector: "app-accommodation-index",
  templateUrl: "./accommodation-index.component.html",
})
export class AccommodationIndexComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService
  ) {}

  links: Link[] = [];

  loading = false;
  notification = {};
  refreshing = true;
  accommodation: any;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  requestPromise = this.dataService.accommodationChecks();

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message) {
      if (message === "submittedtoqueue=1") {
        this.notification = {
          message:
            "Your accommodation request has been submitted. Kindly note that this is not a confirmation of allocation. You will recieve an email when your request has been processed.",
        };
      } else if (message === "submittedtoqueue=0") {
        this.notification = {
          message: "Your accommodation reservation was successful.",
        };
      } else {
        this.notification = {
          message: message.replace(/\+/g, " "),
        };
      }
    }

    

    //this.accommodation = authData("_accommodation");
    this.requestPromise
      .then((result) => {
        this.accommodation = result;

        this.links.push({
          title: "Accommodation Application",
          slug: "/accommodation/instructions",
          icon: "/assets/icons/home-button.png",
          text: "Add a line or two here that explains what this card does",
        });

        if (
          !this.accommodation.AccommodationEnabled ||
          !this.accommodation.SessionAccommodationEnabled ||
          !this.accommodation.EnabledForLevel ||
          !this.accommodation.AllowAccommodationAccess
        ) {
          this.links.pop();
        }

        if (this.accommodation.ActiveReservation)
          this.links.push({
            title: "Accommodation Booking",
            slug: "/accommodation/confirmation",
            icon: "/assets/icons/document.png",
            text: "Add a line or two here that explains what this card does",
          });

        if (this.accommodation.ReservationFullyPaid)
          this.links.push({
            title: "Accommodation Payment Confirmation",
            slug: "/accommodation/payment",
            icon: "/assets/icons/receipt.png",
            text: "Add a line or two here that explains what this card does",
          });

        if (this.accommodation.QueueAccommodation)
          this.links.push({
            title: "Accommodation Application Status",
            slug: "/accommodation/status",
            icon: "/assets/icons/history.png",
            text: "Add a line or two here that explains what this card does",
          });

        if (!this.links.length) {
          this.notification = {
            error: true,
            message: "Accommodation not enabled",
          };
        }
        this.refreshing = false;
      })
      .catch((error) => {
        this.refreshing = false;
        const err = JSON.parse(JSON.stringify(error));
        this.notification = {
          error: true,
          message: err?.error?.Message,
        };
      });

    // {
    //   title: "ID Replacement Applications",
    //   slug: "/accommodation/id-replacement",
    //   icon: "/assets/icons/id-card.png",
    //   text: "Add a line or two here that explains what this card does",
    // },
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
