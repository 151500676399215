<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Student Transcript Application
        </header>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>

            <h1 class="font-medium text-left text-l">
                The Academic Transcript (Student Copy) is not intended for official use.<br />
                The generated Academic Transcript (Student Copy) will be watermarked accordingly Cost for student copy
                is N5,000 (Five Thousand Naira Only)<br />
                Academic Transcript (Student Copy) downloaded will be accessible through the session of payment
            </h1>

            <div class="grid gap-8 grid-cols-1">
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Email
                    </div>
                    <input required type="text" name="email" [(ngModel)]="payload.ApplicantEmail"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Programme
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="programme" class="w-full p-2 focus:outline-none" name="programme">
                                <option disabled value="">Select a programme</option>
                                <option *ngFor="let programme of payload.StudentProgrammeDTO">
                                    {{ programme.ProgrammeID }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>

        <div class="py-12" *ngIf="payload.DownloadTranscript">
            <button (click)="downloadTranscript(studentTranscript)"
                *ngFor="let studentTranscript of payload.StudentTranscriptApplicationsDTO" class="cursor-pointer font-medium text-green-700 text-sm w-full uppercase whitespace-nowrap
            hover:text-green-300 text-left" [disabled]="loading">
                Download Your Student Transcript({{studentTranscript.ProgrammeName}})
            </button>
        </div>

    </section>
</app-dashboard-layout>