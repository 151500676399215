<nav class="text-sm overflow-hidden">
  <ul class="pl-0">

    <div
      class="bg-white w-3/5 z-100 md:z-0 absolute md:relative h-screen bg-gradient-to-tr from-primary to-primary/60 block md:hidden"
      style="
      z-index: 100;" *ngIf="open">
      <li class="block" *ngFor="let link of links">
        <a [routerLink]="link.slug"
          routerLinkActive="bg-primary/5 border-t-2 md:border-0 md:border-r-2 border-white md:!border-primary"
          [routerLinkActiveOptions]="{ exact: link.name == 'home' }"
          class="flex flex-row p-3 text-white md:text-gray-600 gap-2 duration-300 items-center hover:text-black md:text-primary">
          <div class="text-lg material-icons inline-block">{{ link.icon }}</div>
          <div class="whitespace-nowrap block">
            {{ link.title }}
          </div>
        </a>
      </li>
    </div>
    <div class="p-2 bg-transparent absolute md:relative h-screen hidden md:block">
      <li class="block" *ngFor="let link of links">
        <a [routerLink]="link.slug"
          routerLinkActive="bg-primary/5 border-t-2 md:border-0 md:border-r-2 border-white md:!border-primary"
          [routerLinkActiveOptions]="{ exact: link.name == 'home' }"
          class="flex flex-row p-3 text-gray-600 gap-2 duration-300 items-center hover:text-primary">
          <div class="text-lg material-icons inline-block">{{ link.icon }}</div>
          <div class="whitespace-nowrap block">
            {{ link.title }}
          </div>
        </a>
      </li>
      <div class="border-t hidden md:block"></div>
      <li *ngFor="let link of xLinks" class="hidden md:block">
        <a target="_blank" (click)="external(link.isImpata)"
          class="my-2 p-3 text-gray-600 duration-300 block md:px-8 md:gap-4 hover:text-primary">
          <img [src]="link.icon" [alt]="link.title" class="h-auto w-20" />
        </a>
      </li>
    </div>
  </ul>
</nav>