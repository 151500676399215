<app-dashboard-layout [loading]="loading" [downloader]="downloader">
    <app-notification-alert [data]="notification"></app-notification-alert>

    <section *ngIf="refreshing">
        <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
        <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
        <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
    </section>

    <section *ngIf="!refreshing">
        <app-no-content *ngIf="!payload" caption="No transcript application"> </app-no-content>

        <section *ngIf="payload && payload.Amount" class="flex flex-col gap-4">
            <a (click)="onClickPay()"
                class="bg-white rounded-md cursor-pointer border-2 p-4 transform duration-200 block group md:p-6 hover:border-primary hover:bg-primary/5 hover:scale-95">
                <div class="font-medium text-base pb-2 capitalize group-hover:text-primary">
                    {{ payload.CollectionType }}
                </div>
                <div class="font-mono text-base pb-2 group-hover:text-primary">
                    {{ payload.RRR }}
                </div>

                <section class="flex gap-8 justify-between items-center">
                    <div class="text-sm text-gray-500">₦{{ payload.Amount.toLocaleString() }}</div>
                    <a class="font-medium text-primary text-sm">PAY</a>
                </section>
            </a>

            <div *ngIf="payload" class="flex justify-center">
                <button [disabled]="loading" (click)="paymentAdvice()" class="bg-primary/90 rounded font-medium text-white text-sm py-2 px-4 uppercase
                whitespace-nowrap hover:bg-primary whitespace-nowrap"> Get Payment
                    Advice
                </button>
            </div>
        </section>
    </section>
</app-dashboard-layout>

<section role="dialog" aria-modal="true" aria-labelledby="modal-title" class="inset-0 z-10 fixed overflow-y-auto"
    *ngIf="show">
    <div class="flex flex-col h-full text-center p-4 items-center justify-center">
        <div aria-hidden="true" class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"></div>

        <div
            class="bg-white rounded-lg shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8">
            <section class="p-4">
                <div>
                    <section class="pb-4">
                        <div class="text-sm pb-1 text-gray-500">Payment for:</div>
                        <div class="font-medium text-lg pb-1 capitalize group-hover:text-primary">
                            {{ payload.CollectionType }}
                        </div>
                    </section>

                    <div class="pb-4 gap-4 justify-between items-center md:flex">
                        <section class="">
                            <div class="text-sm pb-1 text-gray-500">Name:</div>
                            <div class="font-medium text-base pb-1 capitalize group-hover:text-primary">
                                {{ payload.FullName }}
                            </div>
                        </section>

                        <section class="md:text-right">
                            <div class="text-sm pb-1 text-gray-500">Amount to pay:</div>
                            <div class="text-sm text-gray-500">₦{{ payload.Amount.toLocaleString() }}</div>
                        </section>
                    </div>
                </div>

                <nav class="grid pt-4 gap-4 grid-cols-2">
                    <button (click)="toggleDialog()"
                        class="bg-white border border-error rounded font-medium text-error text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-error hover:text-white">
                        Close
                    </button>

                    <app-button-primary class="w-full" [disabled]="!payload.ResponseText">
                        <a target="_blank" (click)="toggleDialog()" [href]="'https://pay.tranzgate.com.ng/MakePayment.aspx?id=' +
                            payload.ResponseText +'&allowupdate=1&customername=' +
                            payload.FullName + '&returnurl=' + callBackUrl">
                            Pay Online
                        </a>
                    </app-button-primary>
                </nav>
            </section>
        </div>
    </div>
</section>