import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";
import { environment } from "./../../../../environments/environment";
import { authData, saveAuth } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-bio-data-passport",
  templateUrl: "./bio-data-passport.component.html",
})
export class BioDataPassportComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  url: any = null;
  studentPassport = null;
  _checks = authData("_checks");

  loading = false;
  payload: any = {};
  notification = {};
  status: boolean = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    if (this._checks.PassportPhotoLocked) {
      this.notification = {
        error: true,
        message: "Passport locked!",
      };
    }

    const baseUrl = `${environment.BASE_URL}/users/passport`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.studentPassport = res.Data.PassportData;
        }
      },
    });
  }

  generatePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  updatePassport() {
    if (this._checks.PassportPhotoLocked || this.loading) return;

    this.loading = true;
    this.notification = {};
    this.payload = {
      ImageSize: "2",
      ImageType: "png",
      PassportData: this.url.replace(/^data:image\/\w+;base64,/, ""),
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };

    const baseUrl = `${environment.BASE_URL}/users/savePassport`;
    this.httpClient.post(baseUrl, this.payload, httpOptions).subscribe(
      (res: any) => {
        saveAuth({ ...authData(), StudentPassport: this.url.split(",")[1] });
        this.router.navigateByUrl("/bio-data#Passport+updated");
      },
      (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      }
    );
  }
}
