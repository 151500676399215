import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-transcript-collection",
  templateUrl: "./application-transcript-collection.component.html",
  styleUrls: ["./application-transcript-collection.component.scss"],
})
export class ApplicationTranscriptCollectionComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  payload: any = {};

  programme = "";
  response = {};

  notification = {};
  downloader: any = null;

  loading = false;
  refreshing = true;

  selectedPayment: any = null;
  show = false;
  rrr = "";
  id: any = null;

  callBackUrl =
    "https://studentportal.mtu.edu.ng:7000/payments/RedirectFromTrazgate";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    const baseUrl = `${environment.BASE_URL}/users/StudentTranscriptTranzgate/?collectionId=${this.id}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
        this.refreshing = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }

  onClickPay() {
    this.toggleDialog();
  }

  paymentAdvice() {
    this.loading = true;
    this.notification = {};

    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/users/StudentTranscriptPaymentAdvice?collectionId=${this.id}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
          this.loading = false;
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  toggleDialog() {
    this.show = !this.show;
  }
}
