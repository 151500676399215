import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/http/data.service';

@Component({
  selector: 'app-accommodation-instructions',
  templateUrl: './accommodation-instructions.component.html',
  styleUrls: ['./accommodation-instructions.component.scss']
})
export class AccommodationInstructionsComponent implements OnInit {
  ProgrammeTypeAccomodationInstructions: string | null = null;
  acknowledged = false;
  loading = false;

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    let instructions = this.dataService.accommodationInstructions;
    if (instructions === null || instructions === undefined) {
        instructions = await this.dataService.getAccommodationInstructions();
    }
    this.ProgrammeTypeAccomodationInstructions = instructions;
    this.loading = false;
  }

  toggleAcknowledgment(event: Event) {
    this.acknowledged = (event.target as HTMLInputElement).checked;
  }

  acknowledgeInstructions() {
    this.acknowledged = true;
    this.router.navigateByUrl(`/accommodation/application`);
  }

  back() {
    this.router.navigateByUrl(`/accommodation`);
  }
}
