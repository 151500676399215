import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-accommodation-application-index",
  templateUrl: "./accommodation-application-index.component.html",
})
export class AccommodationApplicationIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  accommodation: any;
  hall = "";
  halls: Halls[] = [];

  loading = false;
  refreshing = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };

    this.accommodation = authData("_accommodation");

    // if (this.accommodation.ActiveReservation) {
    //   this.router.navigateByUrl(`/accommodation/confirmation`);
    // }

    // if (this.accommodation.AccommodationRequiresRegistration) {
    //   if (!this.accommodation.HasRegistered) {
    //     this.notification = {
    //       error: true,
    //       message:
    //         "You must register for the current session before making an accommodation reservation",
    //     };
    //     return;
    //   }
    // }

    const baseUrl = `${environment.BASE_URL}/accomodation/accomodationHalls`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.halls = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err: any) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.refreshing = false;
      },
    });
  }

  selectHall() {
    console.log(`Here ${this.hall}`);
    const safeHall = this.hall.replace(/\(/g, "%28").replace(/\)/g, "%29");
    const encodedHall = encodeURIComponent(safeHall);
    console.log(`Here1 ${encodedHall}`);
    this.router.navigateByUrl(`/accommodation/application/${encodedHall}`);
  }
}

interface Halls {
  HallName: string;
  HallOfResidenceId: string;
}
