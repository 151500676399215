<app-dashboard-layout>
  <app-notification-alert [data]="notification"></app-notification-alert>
  <form class="flex flex-col mx-auto max-w-screen-md p-4 z-20 justify-center">
    <h1 class="font-medium text-center text-lg pb-6">
      Select a payment for Session {{session}}
    </h1>

    <section *ngIf="skeletonLoader">
      <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
      <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
    </section>

    <section *ngIf="!skeletonLoader" class="flex flex-col gap-4">
      <a *ngFor="let res of payload" class="bg-white rounded-md cursor-pointer border-2 p-4 block md:p-6">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-8 self-center md:col-span-3">
            <a class="font-medium text-primary text-sm">{{ res.TranzgateClientDesc }}</a>
          </div>
          <div class="col-span-12 md:col-span-7">
            <table class="text-sm text-gray-500 table-fixed">
              <tbody>
                <tr *ngFor="let collection of res.CollectionAmount">
                  <td scope="col" class="px-4 py-2 border w-3/4">
                    {{collection.CollectionTypeName | titlecase}}
                  </td>
                  <td scope="col" class="px-4 py-4 border w-1/4 text-right">
                    ₦{{ (+collection.Amount).toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <section class="col-span-4 self-center md:col-span-2">
            <app-button-primary (click)="payOnTranzgate(res)" [disabled]="skeletonLoader || loading">
              PAY
            </app-button-primary>
          </section>
        </div>
      </a>
    </section>
  </form>
</app-dashboard-layout>